import {
  Store,
  ReducersMapObject,
  Middleware,
  compose,
  createStore,
  combineReducers,
  applyMiddleware,
  Reducer,
  AnyAction,
} from 'redux';
import isEmpty from 'lodash/isEmpty';

export class StoreManager {
  private store: Store | null = null;

  private reducerMap: ReducersMapObject = {};

  private middleware: any[] = [];

  registerReducers(reducerMap: ReducersMapObject): void {
    Object.entries(reducerMap).forEach(([name, reducer]) => {
      this.reducerMap[name] = reducer;
    });
  }

  registerMiddleware(middleware: Middleware[]): void {
    this.middleware.push(...middleware.map(mid => mid(this.store as Store)));
  }

  createRootReducer(): Reducer {
    const reducers = Object
      .keys(this.reducerMap)
      .reduce((result, key) => Object.assign(result, {
        [key]: this.reducerMap[key],
      }), {});

    if (isEmpty(reducers)) return state => state;

    return combineReducers(reducers);
  }

  createStore<T>(initialState = {}): Store<T> {
    const composeEnhancers = (
      /* eslint-disable-next-line */
            typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose
    );
    const enhancer = () => (
      (next: Function) => (action: AnyAction) => (
        (compose as any)(...this.middleware)(next)(action)
      )
    );

    this.store = createStore(this.createRootReducer(), initialState, composeEnhancers(
      applyMiddleware(enhancer),
    ));

    return this.store;
  }

  refreshStore(): void {
    if (this.store) {
      this.store.replaceReducer(this.createRootReducer());
    }
  }

  isCreated(): boolean {
    return this.store !== null;
  }

  dispatch(action: (payload: any) => AnyAction, payload: any) {
    this.store?.dispatch(action(payload));
  }
}
