/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import * as Yup from 'yup';
import { Crate } from '@piwikpro/platform';
import TranslationCrate from '@piwikpro/translation-crate';
import { FormValidator } from './FormValidator';

@Crate({
  name: 'FormCrate',
  imports: [TranslationCrate],
  registry: (bind: Function) => {
    bind('Yup').toConstantValue(Yup);
  },
  services: [
    { name: 'validation', provide: FormValidator },
  ],
})
export class FormCrate {}
