import { RouterState } from 'connected-react-router';
import {
  createAction,
  handleActions,
} from 'redux-actions';

const GUIDES_QUERY_PARAM = 'guides=';
const GUIDES_QUERY_PARAM_SEPARATOR = ',';

export const guideDeleteSucceeded = createAction<string, string>(
  '@platform/command/guide/delete/success',
  (payload: string) => payload,
);

export interface IGuidesState {
  guides: string[]
}

const initialState: IGuidesState = {
  guides: [],
};

const reducer = handleActions<IGuidesState, RouterState>({
  [guideDeleteSucceeded.toString()]: (state, { payload }: { payload: any }) => ({
    ...state,
    guides: state.guides.filter(element => element !== payload),
  }),
  '@@router/LOCATION_CHANGE': (state, { payload }: { payload: RouterState }) => {
    const { search } = payload.location;

    if (payload.location.search.includes(GUIDES_QUERY_PARAM)) {
      const guidesList = search.split(GUIDES_QUERY_PARAM).pop()?.split('&')[0].split(GUIDES_QUERY_PARAM_SEPARATOR);
      return {
        ...state,
        guides: guidesList || [],
      };
    }
    return state;
  },
}, initialState);

export default reducer;
